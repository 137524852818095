import API_CONST from "@/constants/ApiConst";
import { httpClient, httpClientEnrollment } from "@/services/common/httpClient";
import {
  AddUpdateStorePolicyPayload,
  ConvenienceFeeGetResponse,
  ConvenienceFeePutRequest,
  CustomCheckoutOption,
  Discount,
  GetTaxRatesResponse,
  StorePolicy,
  StripeAccountResponse,
} from "@/Model/payments/types";

export function getStripeOnboardingLink(accountId: number) {
  return httpClient().get<string>(
    `${API_CONST.GET_STRIPE_ONBOARDING_LINK}/${accountId}`
  );
}

export function getStripeOnboardingStatus(accountId: number) {
  return httpClient().get<StripeAccountResponse>(
    `${API_CONST.GET_STRIPE_ONBOARDING_STATUS}/${accountId}`
  );
}

export function getTaxRates(accountId: number) {
  const url = `${API_CONST.GET_TAX_RATES}/${encodeURIComponent(accountId)}`;
  return httpClient().get<GetTaxRatesResponse>(url);
}

export function fetchStorePolicy(accountId: number) {
  return httpClient().get<StorePolicy>(
    `${API_CONST.STORE_POLICY}?accountId=${accountId}`
  );
}

export function saveStorePolicy(payload: AddUpdateStorePolicyPayload) {
  return httpClient().post<StorePolicy>(`${API_CONST.STORE_POLICY}`, payload);
}

export function fetchConvenienceFee(accountId: number) {
  return httpClient().get<ConvenienceFeeGetResponse>(
    `${API_CONST.CONVENIENCE_FEE}?accountId=${accountId}`
  );
}

export function saveConvenienceFee(payload: ConvenienceFeePutRequest) {
  return httpClient().put<ConvenienceFeeGetResponse>(
    `${API_CONST.CONVENIENCE_FEE}`,
    payload
  );
}

export function createCustomCheckout(
  customOption: Pick<
    CustomCheckoutOption,
    "name" | "instructions" | "autoEnrollStatusId" | "accountID" | "isEnabled"
  >
) {
  return httpClient().post<CustomCheckoutOption[]>(
    `${API_CONST.CUSTOM_CHECKOUT}/create`,
    customOption
  );
}

export function updateCustomCheckout(
  customOption: Pick<
    CustomCheckoutOption,
    "name" | "instructions" | "autoEnrollStatusId" | "id"
  >
) {
  return httpClient().put<CustomCheckoutOption[]>(
    `${API_CONST.CUSTOM_CHECKOUT}/update`,
    customOption
  );
}

export function getCustomCheckoutOption(customCheckoutOptionId: number) {
  return httpClient().get<CustomCheckoutOption[]>(
    `${API_CONST.CUSTOM_CHECKOUT}/${customCheckoutOptionId}`
  );
}

export function getCustomCheckoutOptionList(accountId: number) {
  return httpClient().get<CustomCheckoutOption[]>(
    `${API_CONST.CUSTOM_CHECKOUT}/listbyaccountid/${accountId}`
  );
}

export function updateCustomOptionStatus({
  customCheckoutId,
  isEnabled,
}: {
  customCheckoutId: number;
  isEnabled: boolean;
}) {
  return httpClient().post<CustomCheckoutOption[]>(
    `${API_CONST.CUSTOM_CHECKOUT}/togglestatus`,
    {
      customCheckoutId,
      isEnabled,
    }
  );
}

export function createAutomaticDiscount(
  payload: Omit<Discount, "id" | "isActive">
) {
  return httpClientEnrollment().post<void>(`${API_CONST.DISCOUNT}`, payload);
}

export function updateAutomaticDiscount(payload: Discount) {
  return httpClientEnrollment().put<void>(`${API_CONST.DISCOUNT}`, payload);
}

export function getAutomaticDiscountsByAccount(accountId: number) {
  return httpClientEnrollment().get<Discount[]>(
    `${API_CONST.DISCOUNT}/account/${accountId}`
  );
}

export function deleteAutomaticDiscount(discountId: number) {
  return httpClientEnrollment().delete<boolean>(
    `${API_CONST.DISCOUNT}/${discountId}`
  );
}

export function toggleStatusAutomaticDiscount(payload: {
  isActive: boolean;
  id: number;
}) {
  return httpClientEnrollment().post<boolean>(
    `${API_CONST.DISCOUNT}/toggleactivestatus`,
    payload
  );
}
